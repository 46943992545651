<ng-container *ngIf="!isEdit">
  <button (click)="onEdit(true)" *ngIf="params.context?.inlineMode" class="btn btn-outline-hover-primary btn-icon">
    <i class="la la-edit"></i>
  </button>
  <button (click)="onDelete()" *ngIf="params.context?.inlineMode" class="btn btn-outline-hover-danger btn-icon">
    <i class="la la-trash"></i>
  </button>
  <ng-container *ngFor="let action of params.actions">
    <i class="la la-{{action.icon}} disabled" *ngIf="action.disabled && action.disabled(params)"></i>
    <button *ngIf="(!action.hidden || !action.hidden(params)) && (!action.disabled || !action.disabled(params))" (click)="action.action(params)" class="btn btn-outline-hover-primary btn-icon">
      <i class="la la-{{action.icon}}"></i>
    </button>
  </ng-container>
</ng-container>
<div class="edit-mode" *ngIf="isEdit">
  <button (click)="onCancel(true)" class="btn btn-danger btn-icon">
    <div>Cancel</div>
  </button>
  <button (click)="onSave()" class="btn btn-primary btn-icon">
    <div>Save</div>
  </button>
</div>
