import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FiltersBar, FilterData } from "src/app/shared/components/filters-bar/models/filters-bar.model";
import { DEFAULT_TIME_PERIOD_OPTIONS, TimeRangePayload } from "src/app/shared/components/time-range-picker/time-range-picker.model";
import { NgChanges } from "src/app/shared/extend-angular-classes/on-changes";
import { EntityType } from "src/app/shared/models/entity-type.enum";
import { Entity } from "src/app/shared/models/entity.model";
import { LegacySeverity } from "src/app/shared/models/severity.model";
import { TimePeriodOption, TimePeriodPayload, TimeUnit } from "src/app/shared/models/time.model";
import { ActionsListQueryParamValue, ActionsListQueryParamsEnum } from "../../models/actions-list-query-params.model";
import { ActionsCommonFiltersService } from "../../services/actions-common-filters.service";
import { ActionsEntitiesFilterService } from "../../services/actions-entities-filters.service";
import { ActionsFiltersStateStore } from "../../services/actions-filters-state.service";

@Component({
  selector: 'app-actions-filters',
  templateUrl: './actions-filters.component.html',
  styleUrls: ['./actions-filters.component.scss']
})
export class ActionsFiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentEntity: Entity;
  @Input() highOrg: Entity;
  @Input() showFilters: boolean = true;
  @Output() queryParamChanged: EventEmitter<ActionsListQueryParamValue> = new EventEmitter();
  @Input() timeBack: number = 4;
  @Input() timeUnit: TimeUnit = TimeUnit.HOURS;
  @Input() date: Date = new Date();
  @Input() refresh: boolean = false;
  @Input() dataToExcel: any;
  previousHighOrg  : Entity;
  queryParams: any;
  actionsFilters: FiltersBar<any, any>[] = [];
  initialized: boolean = false;
  searchText: string;

  constructor(
    private actionsCommonFiltersService: ActionsCommonFiltersService,
    private actionsEntitiesFilterService: ActionsEntitiesFilterService,
    private actionsFiltersStateStore: ActionsFiltersStateStore,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.previousHighOrg  = this.highOrg
    this.queryParams = this.activatedRoute.snapshot?.queryParams;

    //Disable filter Persistance
    this.actionsFiltersStateStore.clearState(this.currentEntity?.type);

    if (this.queryParams) {
      if (this.queryParams['clearFilters']) {
        this.actionsFiltersStateStore.clearState(this.currentEntity?.type);
      }
      if (this.queryParams['severity']) {
        this.actionsFiltersStateStore.addFilterValue(this.currentEntity?.type, ActionsListQueryParamsEnum.Severities,
          [LegacySeverity.toString(this.queryParams['severity'])]);
        this.actionsFiltersStateStore.updateState();
      }

      if (this.queryParams['startDate']) {
        this.queryParamChanged.emit({ [ActionsListQueryParamsEnum.StartDate]: this.queryParams['startDate'], [ActionsListQueryParamsEnum.EndDate]: new Date().toISOString() });
      }
      else {
        this.queryParamChanged.emit({ [ActionsListQueryParamsEnum.StartDate]: new Date().toISOString(), [ActionsListQueryParamsEnum.EndDate]: new Date().toISOString() })
      }
    }

    this.initialized = true;
  }

  ngOnChanges(changes: NgChanges<ActionsFiltersComponent>) {
    if (this.highOrg && this.previousHighOrg != this.highOrg) {
      this.previousHighOrg = this.highOrg;
      this.initiateFilters();
    }
  }

  ngOnDestroy() {
    if (this.queryParams && this.queryParams['severity']) {
      this.actionsFiltersStateStore.clearState(this.currentEntity?.type);
    }
    else {
      this.actionsFiltersStateStore.updateState();
    }
  }

  onSearch(searchText: string) {
    this.searchText = searchText;
    this.queryParamChanged.emit({
      [ActionsListQueryParamsEnum.Search]: this.searchText
    });
  }

  initiateFilters() {
    this.actionsCommonFiltersService.loadCommonFilters(this.currentEntity.type).subscribe(filters => {
      if (this.currentEntity && (this.currentEntity.type == EntityType.ORGANIZATION || this.currentEntity.type == EntityType.TENANT)) {
        this.actionsEntitiesFilterService.generateEntitiesFilters(this.actionsFilters, this.currentEntity, this.highOrg);
      }
      this.actionsFilters = this.actionsFilters.concat(filters);
    });
  }

  onFilterItemSelected(items: any) {
      this.actionsEntitiesFilterService.refreshEntitiesFilters(this.actionsFilters, items);
      const filter = this.actionsFilters.find(bar => bar.name === items[0].filterName);
      if (filter !== undefined) {
        this.queryParamChanged.emit(this.actionsEntitiesFilterService.getChangedParams(filter, items));
      }
      this.actionsFiltersStateStore.addFilterState(this.currentEntity.type, items);
  }

  onTimePeriodSelection(payload: TimeRangePayload) {
    this.queryParamChanged.emit({
      [ActionsListQueryParamsEnum.StartDate]: payload?.range?.start?.toISOString(),
      [ActionsListQueryParamsEnum.EndDate]: payload?.range?.end?.toISOString()
    });
  }
}
