<app-portlet>
  <app-portlet-header [title]="'data.CUSTOMERS.PLURAL' | translate" [hideDefaultTools]="true">
    <ng-container nextToTitle>
      <app-search [searchText]="searchText" class="ml-4" (searchTextChange)="onSearch($event)">
        <ng-container *ngIf="total != null" nextToSearch>{{total}} customers</ng-container>
      </app-search>
    </ng-container>
    <ng-container portletTools>
      <app-time-period-picker class="ml-5" (optionSelected)="onTimePeriodSelected($event)">
      </app-time-period-picker>
    </ng-container>
  </app-portlet-header>
  <app-portlet-body class="portlet-body-entity-list full-screen-widget-portlet-no-margin">
    <app-tenants-grid *ngIf="orgId"
                      [searchText]="searchText"
                      [fetchPaginatedRows]="fetchTenantsCb"
                      [searchText]="searchText"
                      [timeBack]="timeBack"
                      [timeUnit]="timeUnit"
                      [style.height.%]="100"
                      [(refresh)]="refresh"
                      (total)="total = $event">
    </app-tenants-grid>
  </app-portlet-body>
</app-portlet>
