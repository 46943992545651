import {LatestDeviceState} from "../shared/models/single-device.model";

export const ICONS_PATH = "assets/media/netop/";
export const ICONS_TOPOLOGY_PATH = 'assets/media/netop/topology/';
export const UNKNOWN_ICON_PATH = 'assets/media/netop/topology/question-mark.svg';
export const ICONS_MULTI_CLIENTS_PATH = 'assets/media/netop/topology/multi-';
export const RED_RSSI = '-red.svg';
export const YELLOW_RSSI = '-yellow.svg';
export const GREEN_RSSI = '-green.svg';
export const CLIENTS_TOOLTIP_DOT = 'assets/media/netop/topology/tplg-dot';
export const HAND_CLICK_PATH = 'assets/media/netop/actions/hand-click.svg';
export const HEALTH_DOT_PATH = 'assets/media/netop/topology/health-dot';
export const HEALTH_DOT_OK_PATH = `${HEALTH_DOT_PATH}-ok.svg`;
export const HEALTH_DOT_WARNING_PATH = `${HEALTH_DOT_PATH}-warning.svg`;
export const HEALTH_DOT_ERROR_PATH = `${HEALTH_DOT_PATH}-error.svg`;
export const HEALTH_DOT_CRITICAL_PATH = `${HEALTH_DOT_PATH}-critical.svg`;
export const HEALTH_DOT_UNDEFINED_PATH = `${HEALTH_DOT_PATH}-undefined.svg`;
export const FABRICS_ICON_PATH = 'assets/media/netop/fabrics_2x.png';
export const PROPERTIES_ICON_PATH = 'assets/media/netop/properties_2x.png';
export const ADD_PLUS_PATH = 'assets/media/netop/add-plus.png';
export const DELETE_ICON_PATH = 'assets/media/netop/actions/delete.svg';

export namespace HealthIcons {
  const base = 'assets/media/netop/health';
  export const GREEN = `${base}-green.png`;
  export const YELLOW = `${base}-yellow.png`;
  export const RED = `${base}-red.png`;
}

export namespace TopologyIcons {
  const BASE = 'assets/media/netop/topo-icons';
  export const CLOUD = `${BASE}/topo-cloud.svg`;
  export const SDWAN = `${BASE}/topo-sdwan.svg`;
  export const ROUTER = `${BASE}/topo-router.svg`;
  export const SWITCH_STACK = `${BASE}/topo-switch-stack.svg`;
  export const SWITCH = `${BASE}/topo-switch.svg`;
  export const UNIDENTIFIED_DEVICE = `${BASE}/topo-ud.svg`;
  export const UNIDENTIFIED_DEVICE_GROUP = `${BASE}/topo-ud-group.svg`;
  export const ACCESS_POINT = `${BASE}/topo-ap.svg`;
  export const ACCESS_POINT_GROUP = `${BASE}/topo-ap-group.svg`;
  export const FIREWALL = `${BASE}/topo-firewall.svg`;
  export const CAMERA = `${BASE}/topo-camera.svg`;
  export const CELLULAR_GATEWAY = `${BASE}/meraki-cellular.svg`;
  export const VPC = `${BASE}/topo-vpc.svg`;
  export const CLIENT_DESKTOP = `${BASE}/topo-clients-mac.svg`;
  export const CLIENT_DESKTOP_GROUP = `${BASE}/topo-clients-mac-group.svg`;
  export const CLIENT_TABLET = `${BASE}/topo-clients-ipad.svg`;
  export const CLIENT_MOBILE = `${BASE}/topo-clients-iphone.svg`;
  export const CLIENT_MOBILE_GROUP = `${BASE}/topo-clients-iphone-group.svg`;
  export const CLIENT_UNKNOWN = `${BASE}/topo-clients-unknown.svg`;
  export const CLIENT_GROUP = `${BASE}/topo-clients-group.svg`;
}

export namespace ConnectionIcons {
  const BASE = 'assets/media/netop';
  export const WIRED = `${BASE}/wired.svg`;
  export const WIRELESS = `${BASE}/wireless.svg`;
}

export namespace StatusIcons {
  export const ALERT = `${ICONS_PATH}alert.svg`;
  export const UNAVAILABLE = `${ICONS_PATH}unavailable.svg`;
  export const HIBERNATE = `${ICONS_PATH}hibernate.svg`;
  export const OFFLINE = `${ICONS_PATH}offline.svg`;
  export const ONLINE = `${ICONS_PATH}online.svg`;
  export const UNREACHABLE = `${ICONS_PATH}unreachable.svg`;
  export const UNKNOWN = `${ICONS_PATH}question-mark.svg`;
  export const IDLE = `${ICONS_PATH}idle.svg`;

  export function fromDeviceState(state: LatestDeviceState): string {
    switch (state) {
      case LatestDeviceState.Alerting:
        return ALERT;
      case LatestDeviceState.Disabled:
        return UNAVAILABLE;
      case LatestDeviceState.Hibernating:
        return HIBERNATE;
      case LatestDeviceState.Offline:
        return OFFLINE;
      case LatestDeviceState.Online:
        return ONLINE;
      case LatestDeviceState.Unreachable:
        return UNREACHABLE;
      case LatestDeviceState.Idle:
          return IDLE;
      default:
        return UNKNOWN;
    }
  }
}

export namespace SeverityIcons {
  const BASE = `${ICONS_PATH}severity_`;
  export const CRITICAL = `${BASE}critical.png`;
  export const HIGH = `${BASE}high.png`;
  export const MEDIUM = `${BASE}medium.png`;
  export const LOW = `${BASE}low.png`;
  export const NOT_COMPUTED = `${BASE}not_computed.png`;
}
