<div>
  <div class="flex-end mb-4">
    <div>
        <ng-container nextToTitle>
          <app-search [placeholder]="'Search by Device Name'"  [searchText]="searchText" class="ml-4" (searchTextChange)="onSearch($event)">
          </app-search>
        </ng-container>
    </div>
    <div>
      <app-time-range-picker [interval]="timeBack" [timeUnit]="timeUnit" [date]="date"
                (timeRangeSelected)="onTimePeriodSelection($event)"></app-time-range-picker>
    </div>
    <div>
      <ng-container *ngIf="dataToExcel">
        <button  class="btn btn-outline-hover-success btn-icon"
                [exportToExcel]="dataToExcel.data"
                [fileName]="dataToExcel.name"
                title="{{'data.OPEN_PORTS.EXPORT_TO_EXCEL' | translate}}">
            <i class="la la-file-excel fs-1" ></i>
        </button>
      </ng-container>
    </div>
  </div>
  <div class="main-action-filter-col">
    <app-filters-bar *ngIf="actionsFilters && actionsFilters.length > 0 && initialized" [filters]="actionsFilters" (filterSelected)="onFilterItemSelected($event)"
                     [cssColContainer]="'main-action-bar-col'"></app-filters-bar>
  </div>
</div>
