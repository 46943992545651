export type QueryParamValue = {
  [queryName: string]: string | string[];
}

export type AllActionsQueryParams = ActionsListCommonQueryParamsEnum | ActionsListEntitiesQueryParamsEnum;

export type ActionsListQueryParamValue = {
  [key in AllActionsQueryParams]?: string | string[];
}
export type ActionsListCommonParamsValue = {
  [key in ActionsListCommonQueryParamsEnum]?: string | string[];
}
export type ActionsListEntitiesParamsValue = {
  [key in ActionsListEntitiesQueryParamsEnum]?: string;
}


export enum ActionsListQueryParamsEnum {
  ActionType = 'actionType',
  ActionCategory = 'actionCategory',
  ActionStatus = 'status',
  Search = 'search',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Organization = 'Organization',
  Severities = 'severity',
  Tenant = 'Tenant',
  Venue = 'Venue',
}
export enum ActionsListCommonQueryParamsEnum {
  Severities = 'severity',
  ActionType = 'actionType',
  ActionCategory = 'actionCategory',
  ActionStatus = 'status',
  Search = 'search',
  StartDate = 'startDate',
  EndDate = 'endDate'
}
export enum ActionsListEntitiesQueryParamsEnum {
  Organization = 'Organization',
  Tenant = 'Tenant',
  Venue = 'Venue',
}

export type SingleFilterState = {[filterName: string]: string};
export type MultiFilterState = {[filterName: string]: string[]};
export type ViewFilterStates = {[viewName: string]: {
    singleFilters: SingleFilterState;
    multiFilters: MultiFilterState;
  }};
