import { Injectable } from '@angular/core';
import { catchError, Observable, of, take, tap } from 'rxjs';
import { User } from '../../models/users.model';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/state/app.state';
import { GlobalEntitiesService } from './global-entities.service';
import { RoleAccess } from '../../models/role-access.model';
import { Auth0Service } from '../auth0.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends GlobalEntitiesService {
  userIsNetop: boolean = false;

  constructor(private http: HttpClient, store: Store<IAppState>, private authService: Auth0Service) {
    super(store)
  }

  fetchUserById(userId: number): Observable<User> {
    return this.http.get<User>(`users/${userId}`);
  }

  fetchCurrentUser(): Observable<User> {
    return this.http.get<User>(`users/`);
  }

  fetchUserByEmail(email: string): Observable<User> {
    return this.http.get<User>(`users/getByEmail/${email}`);
  }

  updateUser(updatedUser: User): Observable<any> {
    return this.http.put(`users/`, updatedUser);
  }

  deleteUser(userId: number): Observable<any> {
    return this.http.delete(`users/${userId}`);
  }

  fetchDefaultAccess(): Observable<RoleAccess> {
    return this.http.get<RoleAccess>(`globalInfo/access`)
  }

  authenticateUser(): Promise<User> {
    return this.http.post<User>('users/authenticate', {}).pipe(tap(user => {
      if (user.email.includes('@netop.cloud'))
        this.userIsNetop = true;
    })).toPromise();
  }

  //logoutUser()
  logout(): void {
    this.http.post<any>(`logout`, {}).toPromise()
      .finally(() => {
        this.authService.logout()
          .finally(this.clearCache);
      });
  }

  private clearCache() {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie = "name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    indexedDB.databases().then(names => {
      names.forEach(name => {
        indexedDB.deleteDatabase(name.name);
      });
    });
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name);
    });
  }
}
