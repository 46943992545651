import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IGetRowsParams, RowClickedEvent } from 'ag-grid-community';
import { Logger, LoggerService } from 'src/app/shared/services/logger.service';
import { DashboardService } from 'src/app/shared/services/rest-services/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { SeverityCellRendererComponent } from 'src/app/shared/ag-grid/cell-renderers/severity-cell-renderer/severity-cell-renderer.component';
import { IssuesCategoryRendererComponent } from 'src/app/shared/ag-grid/cell-renderers/issues-category-renderer/issues-category-renderer.component';
import { dashboardRowClassRules } from 'src/app/shared/ag-grid/cell-renderers/dashboard-cell-renderers';
import { StoreUserPreferencesService } from 'src/app/shared/services/client-storage-services/internal-storage-services/store-user-preferences.service';
import { ActionsDashboardEntityList } from 'src/app/shared/components/entity-dashboard/entity-list/actions-dashboard-entity-list';
import { EntityGridActionsMenuService } from 'src/app/shared/ag-grid/cell-renderers/actions-menu-renderer/entity-grid-actions-menu.service';
import { StoreWidgetPreferencesService } from 'src/app/shared/services/client-storage-services/internal-storage-services/store-widget-preferences.service';
import { ISingleActionList } from 'src/app/shared/components/single-entities/ISingleActionList';
import { NgChanges } from 'src/app/shared/extend-angular-classes/on-changes';
import { SortFilterParams } from 'src/app/shared/models/sort-filter/sort-filter-by-params.model';
import { ActionsListCommonParamsValue } from '../../models/actions-list-query-params.model';
import { AnalyzeCellRendererComponent } from "../../../../../../shared/ag-grid/cell-renderers/analyze-cell-renderer/analyze-cell-renderer.component";
import { NoRowsOverlayComponent } from "../../../../../../shared/ag-grid/cell-renderers/no-rows-overlay/no-rows-overlay.component";
import { MatDialog } from "@angular/material/dialog";
import { ActionStatus } from "../../../../../../shared/models/actions.model";
import { GridTypes } from "../../../../../../shared/models/client-storage.model";
import { TimeManagerService } from "../../../../../../shared/services/time-manager.service";
import { TimeUnit } from 'src/app/shared/models/time.model';
import { Entity } from 'src/app/shared/models/entity.model';
import { TextTooltipCellRendererComponent } from 'src/app/shared/ag-grid/cell-renderers/text-tooltip-cell-renderer/text-tooltip-cell-renderer.component';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { formatDateTime } from 'src/app/shared/operators/time-operator';
import { OrganizationsService } from 'src/app/shared/services/rest-services/organizations.service';

@Component({
  selector: 'app-actions-list',
  templateUrl: './actions-list.component.html',
  styleUrls: ['./actions-list.component.scss']
})
export class ActionsListComponent extends ActionsDashboardEntityList implements OnInit, ISingleActionList {
  @Input() commonFiltersParams: ActionsListCommonParamsValue;
  @Input() currentEntity: Entity;
  @Input() timeBack: number = 4;
  @Input() timeUnit: TimeUnit = TimeUnit.HOURS;
  @Input() date: Date = new Date();
  @Input() refresh: boolean = false;
  @Output() dataToExcel : EventEmitter<any> = new EventEmitter()
  readonly logger: Logger; components; columnDefs;
  context: any;
  dataToExport :any
  tenantsList : any

  constructor(
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    private loggerFactory: LoggerService,
    protected storeUserPreferencesService: StoreUserPreferencesService,
    protected storeWidgetPreferencesService: StoreWidgetPreferencesService,
    protected organizationService: OrganizationsService,
    private translate: TranslateService,
    private dateConversionService: TimeManagerService,
    private route: ActivatedRoute,
    entityGridActionsMenuService: EntityGridActionsMenuService
  ) {
    super(storeUserPreferencesService, storeWidgetPreferencesService, dashboardService, GridTypes.MAIN_ACTIONS_LIST, entityGridActionsMenuService)
    this.logger = this.loggerFactory.getLogger("ActionsListComponent");
    this.searchQuery$.subscribe(searchText => {
      this.logger.debug(`setting searchText  to ${searchText}`)
      this.searchText = searchText;
      this.gridApi.setDatasource(this.dataSource);
    });

    this.context = { componentParent: this, disableRowHover: true };
    this.components = {
      severityCellRenderer: SeverityCellRendererComponent,
      analyzeCellRenderer: AnalyzeCellRendererComponent,
      issuesCategoryRenderer: IssuesCategoryRendererComponent,
      customNoRowsOverlay: NoRowsOverlayComponent,
      tooltipTextRenderer: TextTooltipCellRendererComponent,
    };

    this.organizationService.fetchTenantsById(1).subscribe(tenants => {
      this.tenantsList = tenants;
      this.dataSource = {
        getRows: (params: IGetRowsParams) => {
          if (this.currentEntity) {
            const sortParams = new SortFilterParams(params, undefined, this.numberOfRawsInPage, "", this.commonFiltersParams);
            params.sortModel = [{ colId: 'updatedAt', sort: 'desc' }];
            sortParams.sortBy = params.sortModel[0] ? params.sortModel[0].colId : undefined;

            this.dashboardService.fetchAnalysisEntityActions(
              this.currentEntity.type,
              this.timeBack,
              this.timeUnit,
              this.date,
              sortParams,
              this.currentEntity.id).subscribe(data => {
                this.dataToExport = null;
                if (data.total == 0) {
                  this.gridApi.showNoRowsOverlay();
                } else {
                  this.gridApi.hideOverlay();
                  this.fetchDataForExcelExport(data, sortParams);
                }
                this.loadData(this.logger, params, data);
              }, () => params.failCallback());
          }
        }
      };
      if (this.gridApi) {
        this.gridApi.setDatasource(this.dataSource);
      }
    });
  }

  openActionModal$(event: RowClickedEvent) {
  }

  ngOnInit() {
    let thisClass = this;
    this.columnDefs = [
      {
        headerName: 'Severity',
        width: 40,
        field: "severity",
        cellRenderer: "severityCellRenderer",
      },
      {
        headerName: this.translate.instant('data.GRIDS_HEADERS.CREATED_AT'),
        field: 'createdAt',
        sortable: true,
        valueGetter: params => {
          if (params?.data?.createdAt)
            return thisClass.dateConversionService.dateByFormat(+params.data.createdAt, 'DD-MMM-YYYY HH:mm');
        }
      },
      {
        headerName: this.translate.instant('data.GRIDS_HEADERS.UPDATED_AT'),
        field: 'updatedAt',
        sortable: true,
        valueGetter: params => {
          if (params?.data?.updatedAt)
            return thisClass.dateConversionService.dateByFormat(+params.data.updatedAt, 'DD-MMM-YYYY HH:mm');
        }
      },
      {
        headerName: this.translate.instant('data.GRIDS_HEADERS.DEVICE'),
        field: 'name',
        width: 40,
        sortable: true,
        hide: this.route.snapshot.url[0].path == 'analysis'
      },
      {
        headerName: this.translate.instant('data.GRIDS_HEADERS.CUSTOMER'),
        field: 'tenantId',
        width: 40,
        valueGetter: params => this.addTenantName(params.data?.tenantId),
        hide: this.currentEntity?.type === "Tenant" || this.currentEntity?.type === "Venue"
      },
      {
        headerName: this.translate.instant('data.GRIDS_HEADERS.SITE'),
        field: 'venueName',
        width: 40,
        hide: this.currentEntity?.type === "Venue"
      },
      {
        headerName: this.translate.instant('data.DASHBOARD.DESCRIPTION'),
        field: 'description',
        tooltipField: "description",
        tooltipShowDelay: 0,
        width: 170,
        sortable: false,
      },
      {
        headerName: this.translate.instant('data.DASHBOARD.CATEGORY'),
        width: 40,
        field: "category",
        cellRenderer: "issuesCategoryRenderer",
        sortable: true,
      },
      {
        headerName: this.translate.instant('data.DASHBOARD.STATUS'),
        field: 'status',
        width: 30,
        sortable: true,
        valueGetter: params => {
          if (params && params.data && params.data.status) {
            return params.data.status === ActionStatus.Complete ?
              this.translate.instant('data.DASHBOARD.RESOLVED') : params.data.status;
          }
        }
      }];

    this.noRowsOverlayComponent = 'customNoRowsOverlay';
    this.noRowsOverlayComponentParams = {
      message: 'No Data Found'
    };
    this.rowClassRules = dashboardRowClassRules;


    this.logger.debug("in ngOnInit of ActionsListComponent")
  }

  ngOnChanges(changes: NgChanges<ActionsListComponent>) {
    if (this.gridApi) {
      if (changes.commonFiltersParams || changes.currentEntity || changes.refresh) {
        this.gridApi.setDatasource(this.dataSource);
      }
    }
  }

  ngOnDestroy(): void {
    this.cancelSubscription();
  }

  addTenantName(tenantID: number): string {
    if (!this.tenantsList || !Array.isArray(this.tenantsList) || this.tenantsList.length === 0) {
      return '';
    }
    const matchingTenant = this.tenantsList.find(tenant => tenant.id === tenantID);
    return matchingTenant ? matchingTenant.name : '';
  }


  getFileName(){
    const now = moment();
    return `anomalies_${formatDateTime(now)}`
  }

  /**
  * Fetches data for Excel export.
  *
  * The grid only displays 100 rows at a time. This function checks if the total number of rows
  * exceeds the number of rows currently available in the grid. If the total is greater, it triggers
  * a new request to fetch additional data based on the updated `pageSize`. If the total rows are
  * less than or equal to the number of rows already available, it simply transforms the current data
  * and prepares it for export.
  *
  * @param data The data object returned from the initial fetch containing the grid data and total row count.
  * @param sortParams The sorting parameters used to fetch the data, including the page size.
  */
  fetchDataForExcelExport(data: any, sortParams: any) {
      if (data.total > data.data.length) {
        sortParams.pageSize = data.total
        this.dashboardService.fetchAnalysisEntityActions(
          this.currentEntity.type,
          this.timeBack,
          this.timeUnit,
          this.date,
          sortParams,
          this.currentEntity.id).subscribe(data => {
                this.dataToExport = this.transformDataForExcel(data.data)
                this.dataToExcel.emit({data : this.dataToExport, name: this.getFileName()})
            })
       } else {
                this.dataToExport = this.transformDataForExcel(data.data)
          this.dataToExcel.emit({data : this.dataToExport, name: this.getFileName()})
        }
    }

    transformDataForExcel(data) {
      const displayedRows = data.map(element => {
        const row: any = {
          severity: element.severity,
          device: element.name,
        };
        if (this.currentEntity?.type !== "Tenant" &&  this.currentEntity?.type !== "Venue") {
          row.customer = this.addTenantName(element.tenantId);
        }
        if (this.currentEntity?.type !== "Venue") {
          row.site = element.venueName;
        }
        row.createdAt = formatDateTime(element.createdAt);
        row.updatedAt = formatDateTime(element.updatedAt);
        row.description = element.description;
        row.category = element.category;
        row.status = element.status;

        return row;
      });

      return displayedRows;
    }


}
