import {HostListener, Input, SimpleChanges, HostBinding, Output, EventEmitter, Directive} from '@angular/core';
import {ColDef, IDatasource, GridApi, ColumnApi, ColumnState} from 'ag-grid-community';
import {Subject, Observable, Subscription} from 'rxjs';
import {filter, debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {Page} from '../../../models/page.model';
import {StoreUserPreferencesService} from '../../../services/client-storage-services/internal-storage-services/store-user-preferences.service';
import {StoreWidgetPreferencesService} from '../../../services/client-storage-services/internal-storage-services/store-widget-preferences.service';
import {GridTypes, GridUserPreferencesOptions} from "../../../models/client-storage.model";

/**
 * The base class for entity list grid component that is placed on grid.
 */
@Directive()
export class BaseDashboardEntityList {
  isLoading: boolean = false;
  currentNode: any;
  noRowsOverlayComponent = 'customNoRowsOverlay';
  noRowsOverlayComponentParams = {
    message: 'No Data Found'
  };

  /**
   * Summary for refreshing data :
   * [1] For refreshing data after changes of the data (like Ack action) call refreshInfiniteCache.
   * [2] For storing last scroll index:
   *      [a] Use scrollBody event to get the scroll top from the event object.
   *      [b] Use the this.gridApi.getRenderedNodes() to get rows with their heights (rowHeight)
   *      [c] Compute the first visible row and store it's index in service (rowIndex)
   *          i.e. scrolledOutRowsNumber = event.top / rowHeight assuming the row height equal
   *          (or just count this in loop)
   *      [d] Use this.gridApi.ensureIndexVisible(index, 'top'); to restore the scrolling
   * @param storeUserPreferencesService
   */
  constructor(
    protected storeUserPreferencesService: StoreUserPreferencesService,
    protected storeWidgetPreferencesService: StoreWidgetPreferencesService,
    gridType: GridTypes) {
    this.gridType = gridType;
    this.initGridPreferences(gridType);
    this.defaultColDef = {
      width: 50,
      resizable: true
    }
    this.firstVisibleRow = -1;
    if (!this.context) {
      this.context = {componentParent: this};
      console.log("Setting component context")
    } else if (!this.context.componentParent) {
      this.context = {componentParent: this, ...this.context};
      console.log("Setting component parent in base dashboard")
    }
  }

  subscription: Subscription[] = [];
  /**
   * Subsribe to changes on the widget list.
   * If changes include grid, it sould refresh its width
   */

  /**
   * Subsribe to changes on the widget list.
   * If changes include grid, it sould refresh its width
   */
  @Output() total: EventEmitter<number> = new EventEmitter()
  @Input() searchText: string;
  @Input() widgetClass: string;
  @Input() refresh: boolean;
  @Output() refreshChange = new EventEmitter<boolean>();
  // entity-list
  @HostBinding('class') class = 'entity-list-component-host';
  /** An observable that emits on search text changed */
  searchQueryChanged: Subject<[string, string]> = new Subject<[string, string]>();
  searchQuery$: Observable<string> = this.searchQueryChanged.pipe(
    // wait 1 sec after the last event before emitting last event
    debounceTime(1000),
    // only filter on search of string greater then or equal 3 chars
    map(([oldVal, newVal]) => {
      //Added option for refetch the data when search box were emptied
      if (newVal.length >= 3 || newVal.length == 0) {
        // The new search value is long enough
        return newVal;
      }
      console.log(`old value ${oldVal}`)
      if (oldVal && oldVal.length >= 3) {
        // If the old value for search was meanigfull - reset the search
        return "";
      }
      return undefined;
    }),
    /// Filter out any search that we mapped to undefined in prev step
    filter((s) => s !== undefined),
    // only emit if value is different from previous value
    distinctUntilChanged(),
  );
  /** Is the agGrid in debug mode */
  debugGrid = false;
  protected gridApi: GridApi;
  protected columnApi: ColumnApi;
  /** agGrid column definition */
  columnDefs: ColDef[];
  /** agGrid default column def */
  defaultColDef;
  // extra paging parameters passed from the server side
  extraPagingParams?: Record<string, string>;
  /** Set the row data for the grid to display it */
  rowData: Array<any> = [];
  frameworkComponents;
  /** The class rule that defines the highlighted row according to it's severity */
  rowClassRules;
  /** server side data binding model  */
  rowModelType = "infinite"
  //cacheBlockSize = 2;
  paginationPageSize = 12;
  /**
   * This number is used as the *total* number of rows of data that ag grid holds in memory, all extra is just thrown out.
   * It should be bigger or equal to the number of rows comming from server.
   * For now since the sever side does not yet support infinite scroll just setting it to infinity
   * This setting is actually the cacheBlockSize setting in the ag-grid.
   */
  numberOfRawsInPage = 100;
  maxConcurrentDatasourceRequests = 1;
  //infiniteInitialRowCount = 1;
  //maxBlocksInCache = 1;
  dataSource: IDatasource;
  context: any;
  rowHeight: number = 38;
  headerHeight = 37;
  icons: any = {
    sortAscending: '<img src="/assets/media/netop/grid/sort-asc.svg"></img>',
    sortDescending: '<img src="/assets/media/netop/grid/sort-desc.svg"></img>',
  }
  //gridUserPreferences: GridUserPreferences;
  //originColumnsState: ColumnState[];
  rowsBuffer: number = 0;
  firstVisibleRow: number;
  //
  disableVisibleRowState: boolean = false;
  gridType: GridTypes;

  /**
   * The search text is shown in portlet header and passed in as
   * input paramter. Changing it will trigger on changes.
   * @param changes changes to input object
   */
  ngOnChanges(changes: SimpleChanges) {
    this.storeUserPreferencesService.currentGrid = this.context.componentParent.gridType;
    if (changes["searchText"] && this.searchText && this.gridApi) {
      this.storeUserPreferencesService.setCurrentGridPreference(GridUserPreferencesOptions.searchTerm,
        changes["searchText"]["currentValue"]);
      this.searchQueryChanged.next([changes["searchText"]["previousValue"], changes["searchText"]["currentValue"]]);
    }
    if (changes['refresh'] && this.refresh) {
      this.gridApi?.setDatasource(this.dataSource);
      this.refresh = false;
      this.refreshChange.emit(false);
    }
  }

  @HostListener('window:resize', ['$event']) onWindowResize(event: any) {
    this.refreshGridWidth();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    if (params.type === 'gridReady' || 'modelUpdated') {
      this.refreshGridWidth();
      if (!this.disableVisibleRowState) {
        this.makeLastRowVisible()
      }
    }
    if (params.type === 'gridReady') {
      this.isLoading = true;
      this.gridApi.setDatasource(this.dataSource);
      this.setOriginalGridState();
    }
  }

  loadData(logger, dataGridParams, pageData: Page<any>) {
    if (pageData) {
      this.extraPagingParams = pageData.extraPagingParams;
      logger.debug("got data %o", pageData)
      // params.startRow,params.endRow
      logger.debug("fetch data params %o", dataGridParams)
      //debugger
      this.rowData = pageData.data;
      dataGridParams.successCallback(this.rowData, pageData.total);
      this.total.emit(pageData.total);
      setTimeout(() => {
        // Need the timeout to remove the horizontal scroll
        this.refreshGridWidth();
        setTimeout(() => {
          //this.gridApi.ensureIndexVisible(10, 'middle');
          // this.gridApi.refreshInfiniteCache()
        }, 500)
      }, 500);
      if (this.gridApi) {
        // this.gridApi.gridPanel.scrollTop = 100;
      }
    }
  }

  refreshGridWidth() {
    if (!this.gridApi) {
      return;
    }
    this.gridApi.sizeColumnsToFit();
  }

  /**
   * @method setGridType Initilized the grid type for the gridUserPreferences param
   * It also set the preferences and the colSorting with empty values, in order to allow later assignment
   * @param gridType The current grid Type
   */
  protected initGridPreferences(gridType: GridTypes) {
    this.storeUserPreferencesService.setCurrentGrid(gridType);
  }

  /**
   * Set single preference value
   * @param prefName preference name to set
   * @param prefValue preference value to set
   */
  protected setGridPreference(prefName: string, prefValue: any) {
    this.storeUserPreferencesService.setCurrentGridPreference(prefName, prefValue);
  }

  /**
   * @method setOriginalGridState Responsible for applying the stored user preferences on the current grid
   * The method is invoked right after the grid setDatasource is invoked
   * In case that there are relevant preferenced for the current grid, the method invoked ag-grid built-in functions
   * and apply them on the grid.
   */
  protected setOriginalGridState() {
    /**
     * The next condition has one goal:
     * In case of loading two grid on the same time (Done in the dynamic dashboard structure) -
     * Make sure that there is similarity between  storeUserPreferencesService and ag grid
     * Only if ag grid the storeUserPreferencesService regering to the same grid type - we can set
     * the grid data
     */
    this.storeUserPreferencesService.currentGrid = this.context.componentParent.gridType;
    const preferences = this.storeUserPreferencesService.getCurrentGridPreferences();
    if (preferences && preferences.hasOwnProperty('columnsState')) {
      let originColumnsState = preferences["columnsState"];
      this.gridApi["columnController"].setColumnState(originColumnsState);
    }
    if (preferences && preferences.hasOwnProperty('colSorting')) {
      let sortingModel = preferences["colSorting"];
      this.columnApi.applyColumnState(sortingModel);
    }
    if (preferences && preferences.hasOwnProperty('searchTerm')) {
      this.searchText = preferences["searchTerm"];
    }
  }

  /**
   * @method onSortChange Invoked when the grid fire the sort change event.
   * It update the current sort model on the "colSorting" param and the uset preferences on storeUserPreferencesService
   * @param change The current change objectr
   */
  onSortChange(change: { type: string, api: GridApi, columnApi: ColumnApi }) {
    this.storeUserPreferencesService.currentGrid = this.context.componentParent.gridType;
    const sortModel = change.columnApi.getColumnState();
    // let lastSortModel = [];
    // lastSortModel = this.storeUserPreferencesService.getCurrentGridPreference(GridUserPreferencesOptions.colSorting);
    // if (lastSortModel && sortModel.length > 0) {
    //   if (!lastSortModel.find(model => model.colId == sortModel[0].colId))
    //     lastSortModel.push(sortModel[0]);
    //   else {
    //     let colIdIndex = lastSortModel.findIndex(model => model.colId == sortModel[0].colId);
    //     lastSortModel[colIdIndex] = sortModel[0];
    //   }
    // } else
    //   lastSortModel = sortModel;
    this.storeUserPreferencesService.setCurrentGridPreference(GridUserPreferencesOptions.colSorting, sortModel);
    console.log("in on sort change")
  }

  /**
   * @method onColDragEnd Invoked when the grid fire the drag end event.
   * The function checks if the currentt column state is different than the one that was saved on the originColumnsState
   * If it is changed, it update the:
   * 1. originColumnsState
   * 2. gridUserPreferences.preferences.columnsState
   * 3. storeUserPreferencesService
   * @param change The current change object
   */
  onColDragEnd(change: { type: string, api: GridApi, columnApi: ColumnApi }) {
    this.storeUserPreferencesService.currentGrid = this.context.componentParent.gridType;

    let originColumnsState: ColumnState[] =
      this.storeUserPreferencesService.getCurrentGridPreference(GridUserPreferencesOptions.columnsState);
    if (!originColumnsState) {
      originColumnsState = [];
    }
    let columnsModel = change.columnApi.getColumnState();
    let isChanged = false;
    if (columnsModel.length !== originColumnsState.length)
      isChanged = true;

    else {
      for (let i = 0; i < columnsModel.length; i++) {
        if (columnsModel[i].colId !== originColumnsState[i].colId)
          isChanged = true;
      }
    }
    if (isChanged) {
      this.storeUserPreferencesService.setCurrentGridPreference(GridUserPreferencesOptions.columnsState, columnsModel);
    }
    ;
  }

  onCellMouseOver(event) {
    console.log("mouse over event %o", event)
  }

  /**
   * Store the first seen row on the store user preferences
   * Using the body scroll event.top parameter, and the getRenderedNodes method allow us to calculate
   * the first seen row.
   *
   */
  bodyScroll(event: { type: string, direction: string, api: GridApi, columnApi: ColumnApi, left: number, top: number }) {
    this.storeUserPreferencesService.currentGrid = this.context.componentParent.gridType;
    if (this.gridApi) {
      let rowsHight = this.gridApi.getRenderedNodes();
      let firstRow = rowsHight.find(row => row.rowTop > event.top);
      if (firstRow !== undefined) {
        this.storeUserPreferencesService.setCurrentGridPreference("gridStartRow", firstRow.rowIndex);
      }
    }
  }

  /**
   * @method onViewPortChanges Emiited with every grid scroll event.
   * The method save the rendered rows buffer, according to the last and first rows.
   * Then it substract the buffer from the lastrow number.
   * @param event ViewPortChanges Object
   */
  onViewPortChanges(event: { type: string, firstRow: number, lastRow: number, api: GridApi, columnApi: ColumnApi }) {

  }

  /**
   * @method makeLastRowVisible Set the visible rows on the grid.
   * The function invoked ensureIndexVisible function with the current grid startrow param, and set it to the top
   */
  private makeLastRowVisible() {
    this.storeUserPreferencesService.currentGrid = this.context.componentParent.gridType;
    let rowIndex = this.storeUserPreferencesService.getCurrentGridPreference(GridUserPreferencesOptions.gridStartRow);
    if (rowIndex !== undefined && this.gridApi.getDisplayedRowAtIndex(rowIndex)) {
      this.gridApi.ensureIndexVisible(rowIndex, 'top')
    }
  }

  protected markRowNodeAsSelected(property: string) {
    if (this.currentNode) {
      setTimeout(() => {
        this.gridApi.forEachNode(node => {
          if (node.data?.length)
            node.setSelected((node.data[property]) === (this.currentNode.data[property]));
        });
      }, 50);
    }
  }

  onColumnResized(event: any) {
    if (event.source === "uiColumnDragged" && event.finished) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  cancelSubscription() {
    this.subscription.forEach(subsc => subsc.unsubscribe());
  }
}

